/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "./_theme-colors.scss" as theme;
@use "primeng/resources/primeng.min.css";
@use "primeicons/primeicons.css";
@use "plyr/src/sass/plyr.scss";
@use "@ctrl/ngx-emoji-mart/picker.css";
@use "quill/dist/quill.core.css" as quill-core;
@use "quill/dist/quill.snow.css" as quill-snow;

@layer tw-base, primeng-custom, primeng, tw-components, tw-utilities;
@import "tailwindcss/base.css" layer(tw-base);
@import "tailwindcss/components.css" layer(tw-components);
@import "tailwindcss/utilities.css" layer(tw-utilities);


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap');

html {
  @include mat.theme((
    color: (
      primary: theme.$primary-palette,
      tertiary: theme.$tertiary-palette,
      theme-type: light,
    ),
    typography: Poppins,
    density: 0,
  ));

  // Use high contrast dark theme colors when users prefer contrast
  @media (prefers-contrast: more) {
    @include theme.high-contrast-overrides(dark);
  }
}

// @import "/src/app/assets/theme/theme.css";

// $enable-important-utilities: false; // this prevents bootstrap from applying !important in utility classes.
// @import "bootstrap/scss/bootstrap.scss";


::view-transition-old(root) {
  animation: exit 0.3s ease 0s 1 normal forwards;
}
::view-transition-new(root) {
  animation: enter 0.3s ease 0s 1 normal forwards;
}

@keyframes enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes exit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// reCaptcha Styles
.grecaptcha-badge {
  visibility: hidden !important;
}

// :root {
//   --main-color: #E94538;
//   --customGray: #465a65;
// }

// .dropdown-menu[_ngcontent-ng-c2790165448] {
//   width: 320px !important;
// }
// // End Tel Input
// input.focus\:ring-1:focus {
//   --tw-ring-shadow: none !important;
// }

// input::-ms-clear, input::-ms-reveal {
//   display: none !important;

div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
input,
label,
select,
textarea {
  color: #707070;
}
input {
  border-radius: 16px;
}

// Red Pin
.red-pin,
.red-pin-bottom {
  position: relative;
}

.red-pin::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 50%;
  background-color: #e84538;
  border-radius: 0 5px 5px 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.red-pin-bottom::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 4px;
  background-color: #e84538;
  border-radius: 5px 5px 0 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
// End Red Pin

// Red ScrollBar
.red-scroll {
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: #e84538 #fafbff; /* Thumb color | Track color */
}

.red-scroll::-webkit-scrollbar {
  width: 6px;
  padding-left: 10px;
}

.red-scroll::-webkit-scrollbar-track {
  background: #fafbff;
}

.red-scroll::-webkit-scrollbar-thumb {
  background: #e84538;
  border-radius: 5px;
}

.red-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
// End Red ScrollBar

// Tooltip Text
.p-tooltip-text {
  color: #fff;
}
// End Tooltip Text

// Link Styles
.mention-link {
  color: #0066cc;
}

.external-link {
  color: #0066cc; /* or any other color you prefer for external links */
  text-decoration: underline; /* optional: underline the links */
  line-break: anywhere;
}
// End Link Styles

// PrimeNg Menu
.p-menu,
.p-contextmenu,
.p-dropdown,
.p-dropdown-panel,
.p-overlaypanel,
.p-datepicker,
.p-treeselect,
.p-treeselect-items-wrapper,
.p-treeselect-panel,
.p-overlay {
  border-radius: 16px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
}

.p-dropdown-items p-dropdownitem:first-child > li {
  border-radius: 16px 16px 0px 0px;
}

.p-dropdown-items p-dropdownitem:last-child > li {
  border-radius: 0px 0px 16px 16px;
}

.p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-dropdown span {
  font-size: 14px !important;
}

//  .p-menuitem {
//   border-top: 1px solid #F0F0F4;
//   border-bottom: 1px solid #F0F0F4;
// }

.p-menuitem:first-child,
.p-menuitem:last-child {
  border: none;
}

.p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 1rem;
}
// End PrimeNg Menu\\

// PrimeNG Menu Icons
.fill-success a span:first-child {
  color: #689f38;
}

.fill-error a span:first-child {
  color: #b3261e;
}

.fill-warning a span:first-child {
  color: #fbc02d;
}
// End PrimeNG Menu Icons

// p-dialog start

.p-dialog,
.p-dropdown,
.p-dropdown-panel {
  @apply shadow-none;
}
/* General Dialog Styles */
.p-dropdown,
.p-dropdown-panel {
  @apply rounded-2xl;
}
.p-dialog .p-dialog-header {
  @apply rounded-t-3xl; /* Rounded top corners for the header */
}
.p-dialog .p-dialog-content {
  @apply rounded-b-3xl;
}
/* Remove content's bottom rounding when a footer is present */
.p-dialog .p-dialog-content:has(+ .p-dialog-footer) {
  @apply rounded-b-none;
}

/* Footer always has rounded bottom corners if it exists */
.p-dialog .p-dialog-footer {
  @apply rounded-b-3xl;
}

/* Confirmation Dialog Styles */
.p-confirm-dialog .p-dialog-header {
  @apply rounded-t-3xl;
}
.p-confirm-dialog .p-dialog-content {
  @apply rounded-none; /* Default: no rounding on content */
}
.p-confirm-dialog .p-dialog-footer {
  @apply rounded-b-3xl;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  @apply bg-white w-6 h-6;
}

// p-dialog-end

// p-button-start

.p-button {
  @apply rounded-xl text-xxs md:text-base;
}
.primaryButton .p-button-label {
  @apply text-white;
}

.outlinedButton .p-button-label {
  @apply text-primary;
}
.outlinedButton .p-button {
  @apply text-primary;
}

// .p-dropdown,
// .p-dropdown-panel {
//   @apply rounded-2xl;
// }
// .p-dropdown-item:first-child {
//   @apply rounded-t-2xl;
// }
// .p-dropdown-item:last-child {
//   @apply rounded-b-2xl;
// }
// .p-dropdown-item:hover {
//   @apply bg-secondary-100;
// }
// .p-dropdown-item.p-highlight {
//   @apply bg-primary-100;
// }


.spinner-white {
  @include mat.progress-spinner-overrides(
    (
      active-indicator-color: white,
    )
  );
}

.spinner-primary {
  @include mat.progress-spinner-overrides(
    (
      active-indicator-color: #E94538,
    )
  );
}

.spinner-secondary {
  @include mat.progress-spinner-overrides(
    (
      active-indicator-color: #A8A8AB,
    )
  );
}